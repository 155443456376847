import React from "react"
import styled from "styled-components"
import { SimpleForm } from "./forms"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import ServiceList from "./serviceList"
import { area, siteName } from "../info"

const StyledHeader = styled.header`
  background-image: linear-gradient(
    to right bottom,
    #051937,
    #0e2446,
    #172f56,
    #1f3b67,
    #284778
  );
  height: 140vh;
  padding: 2rem 1rem;
  text-align: center;
  color: #ffffff;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  .button-container {
    width: 80%;
    margin: 0 auto;
    display: flex;
  }
  .button {
    background-color: #0043ce;
    color: #ffffff;
    padding: 0.4rem 0.6rem;
    border-radius: 4px;
    margin: 0.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  p {
    font-size: 0.8rem;
    line-height: 1.5;
  }
`

const Header = () => {
  const data = useStaticQuery(
    graphql`
      query {
        img: file(relativePath: { eq: "handyman-no-bg.png" }) {
          childImageSharp {
            gatsbyImageData(
              width: 424
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )

  // Set ImageData.
  const imageData = getImage(data.img)
  return (
    <StyledHeader>
      <div>
        <h1>Find a Handyman In {area}</h1>
        {/*<SimpleForm />*/}
      </div>
      <GatsbyImage image={imageData} alt={"A Handyman"} />
      <ServiceList />
    </StyledHeader>
  )
}

export default Header
