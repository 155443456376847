import React, { useState } from "react"
import styled from "styled-components"
import {
  Mobile32,
  Camera32,
  BatteryHalf32,
  MicrophoneOff32,
  Hearing32,
  Plug32,
  RainDrop32,
  Help32,
  ChevronRight32,
  Laptop32,
  Tablet32,
} from "@carbon/icons-react"
import { useForm } from "react-hook-form"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

const ServiceListContainer = styled.ul`
  @media (min-width: 800px) {
    width: 30%;
  }
  cursor: pointer;
  padding: 0.4rem 0;
  width: calc(100% - 30px);
  background-color: white;
  color: black;
  border-radius: 4px;
  list-style: none;
  margin: 0 auto;
  box-shadow: 0 8px 16px 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  li {
    padding: 0.2rem 1rem;
    font-size: 0.8rem;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #dcdcdc;
    &:last-child {
      border: none;
    }
  }
  form {
    padding: 1rem 0;
    margin: auto;
    max-width: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      label {
        padding: 0 0.4rem;
      }
    }
    label {
      font-size: 0.7rem;
      font-weight: 300;
      line-height: 1;
    }
    input,
    textarea {
      max-width: calc(100% - 20px);
      border: 2px solid #0043ce;
      border-radius: 4px;
      padding: 0.3rem 0.3rem;
      margin: 0.4rem auto;
    }
    button {
      background-color: #0043ce;
      color: #ffffff;
      padding: 0.2rem 0.3rem;
      border-radius: 4px;
      margin: 0.3rem auto;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      outline: none;
    }
  }
`

const StyledP = styled.p`
  margin: 2rem auto 0 auto;
  font-size: 0.8rem;
`

const Step1 = ({ addFormData, setStep }) => {
  const data = useStaticQuery(graphql`
    query {
      brush: file(relativePath: { eq: "brush.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      faucet: file(relativePath: { eq: "faucet.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      fridge: file(relativePath: { eq: "fridge.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      hammer: file(relativePath: { eq: "hammer.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      lawnmower: file(relativePath: { eq: "lawnmower.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      roof: file(relativePath: { eq: "roof.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
      tools: file(relativePath: { eq: "tools.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 32
            placeholder: BLURRED
            formats: [AUTO, WEBP, AVIF]
          )
        }
      }
    }
  `)
  return (
    <>
      <li
        onClick={() => {
          addFormData(prev => {
            return { ...prev, ...{ service: "repair" } }
          })
          setStep(2)
        }}
      >
        <GatsbyImage image={getImage(data.tools)} alt={"tools"} />
        Repair
        <ChevronRight32 />
      </li>
      <li
        onClick={() => {
          addFormData(prev => {
            return { ...prev, ...{ service: "painting" } }
          })
          setStep(2)
        }}
      >
        <GatsbyImage image={getImage(data.brush)} alt={"brush"} />
        Painting
        <ChevronRight32 />
      </li>
      <li
        onClick={() => {
          addFormData(prev => {
            return { ...prev, ...{ service: "renovate_remodel" } }
          })
          setStep(2)
        }}
      >
        <GatsbyImage image={getImage(data.hammer)} alt={"hammer"} />
        Renovate or Remodel
        <ChevronRight32 />
      </li>
      <li
        onClick={() => {
          addFormData(prev => {
            return { ...prev, ...{ service: "floor_roof" } }
          })
          setStep(2)
        }}
      >
        <GatsbyImage image={getImage(data.roof)} alt={"house"} />
        Floor or Roof
        <ChevronRight32 />
      </li>
      <li
        onClick={() => {
          addFormData(prev => {
            return { ...prev, ...{ service: "installation" } }
          })
          setStep(2)
        }}
      >
        <GatsbyImage image={getImage(data.fridge)} alt={"fridge"} />
        Installation
        <ChevronRight32 />
      </li>
      <li
        onClick={() => {
          addFormData(prev => {
            return { ...prev, ...{ service: "landscaping" } }
          })
          setStep(2)
        }}
      >
        <GatsbyImage image={getImage(data.lawnmower)} alt={"lawnmower"} />
        Landscaping
        <ChevronRight32 />
      </li>
      <li
        onClick={() => {
          addFormData(prev => {
            return { ...prev, ...{ service: "plumbing" } }
          })
          setStep(2)
        }}
      >
        <GatsbyImage image={getImage(data.faucet)} alt={"faucet"} />
        Plumbing
        <ChevronRight32 />
      </li>
      <li
        onClick={() => {
          addFormData(prev => {
            return { ...prev, ...{ service: "other" } }
          })
          setStep(2)
        }}
      >
        <Help32 />
        Other
        <ChevronRight32 />
      </li>
    </>
  )
}

const Step2 = ({ addFormData, setStep }) => (
  <>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ model: "android" } }
        })
        setStep(2)
      }}
    >
      <Mobile32 />
      Android
      <ChevronRight32 />
    </li>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ model: "iphone" } }
        })
        setStep(2)
      }}
    >
      <Tablet32 /> IPhone
      <ChevronRight32 />
    </li>
    <li
      onClick={() => {
        addFormData(prev => {
          return { ...prev, ...{ model: "other" } }
        })
        setStep(2)
      }}
    >
      <Laptop32 /> Other
      <ChevronRight32 />
    </li>
  </>
)

const Step3 = ({ data }) => {
  const { register, handleSubmit, watch, errors } = useForm()
  const onSubmit = subData => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": "long-form",
        ...subData,
      }),
    })
      .then(() => setSubmitted(true))
      .catch(error => alert(error))
  }

  const [submitted, setSubmitted] = useState(false)
  if (submitted) {
    return <StyledP>Thank You! We'll be in touch shortly.</StyledP>
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} name="long-form">
      <input
        type="hidden"
        className="hidden"
        name="form-name"
        value="long-form"
      />
      <label>
        Once you send us your info we will send you a message with a free
        estimate.
      </label>
      <input
        type="text"
        name="full-name"
        defaultValue=""
        placeholder="Full name"
        ref={register({ required: true })}
      />
      <input
        type="email"
        name="email"
        defaultValue=""
        placeholder="Your email"
        ref={register({ required: true })}
      />
      <input
        type="text"
        name="zipcode"
        defaultValue=""
        placeholder="Zip code (optional)"
        ref={register}
      />
      <span>
        <textarea
          placeholder="Short description of the work you want done."
          name="work-desc"
          ref={register}
        ></textarea>
      </span>
      <input
        type="hidden"
        name="service"
        defaultValue={data.service}
        ref={register}
      />

      <button type="submit">
        Get Price Quote <ChevronRight32 />
      </button>
    </form>
  )
}

const ServiceList = () => {
  const [step, setStep] = useState(0)

  const [formData, addFormData] = useState({})

  return (
    <>
      <form name="long-form" netlify data-netlify="true" hidden>
        <input type="text" name="full-name" />
        <input type="email" name="email" />
        <input type="text" name="zipcode" />
        <input type="hidden" name="service" />
        <textarea name="work-desc"></textarea>
      </form>
      <StyledP>Select a service from the list and get a free estimate.</StyledP>
      <ServiceListContainer>
        {step === 0 ? (
          <Step1 addFormData={addFormData} setStep={setStep} />
        ) : step === 1 ? (
          <Step2 addFormData={addFormData} setStep={setStep} />
        ) : (
          <Step3 data={formData} />
        )}
      </ServiceListContainer>
    </>
  )
}

export default ServiceList
